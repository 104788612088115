import './StatusReason.scss';

import { Typography } from '@mui/material';
import { BundleStatusReason } from 'src/types';

export const StatusReason = ({
  className,
  statusReason,
}: {
  className?: string;
  statusReason: BundleStatusReason;
}): JSX.Element => {
  if (statusReason === BundleStatusReason.targetFailed) {
    return (
      <Typography className={className ? className : ''}>
        Target Failed
      </Typography>
    );
  } else if (statusReason === BundleStatusReason.targetDropped) {
    return (
      <Typography className={className ? className : ''}>
        Target Dropped
      </Typography>
    );
  } else if (statusReason === BundleStatusReason.searcherTxFrontrun) {
    return (
      <Typography className={className ? className : ''}>
        Searcher Transaction Frontrun
      </Typography>
    );
  } else if (statusReason === BundleStatusReason.searcherTxLate) {
    return (
      <Typography className={className ? className : ''}>
        Searcher Transaction Mined Late
      </Typography>
    );
  } else if (statusReason === BundleStatusReason.unknown) {
    return (
      <Typography className={className ? className : ''}>Unknown</Typography>
    );
  } else {
    return <></>;
  }
};
