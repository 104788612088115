import { Box, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const updateTime = (from: Date): string => {
  if (!from) {
    return '';
  }

  let timeAgoS = +((Date.now() - new Date(from).valueOf()) / 1000).toFixed();

  const dateArray = [];

  const timeAgoDays = Math.floor(timeAgoS / 86400);
  if (timeAgoDays > 0) {
    dateArray.push(timeAgoDays + (timeAgoDays === 1 ? ' day' : ' days'));
  }
  timeAgoS -= timeAgoDays * 86400;

  const timeAgoHours = Math.floor(timeAgoS / 3600);
  if (timeAgoHours > 0) {
    dateArray.push(timeAgoHours + (timeAgoHours === 1 ? ' hr' : ' hrs'));
  }
  timeAgoS -= timeAgoHours * 3600;

  const timeAgoMinutes = Math.floor(timeAgoS / 60);
  if (timeAgoMinutes > 0 && dateArray.length < 2) {
    dateArray.push(timeAgoMinutes + (timeAgoMinutes === 1 ? ' min' : ' mins'));
  }
  timeAgoS -= timeAgoMinutes * 60;

  if (dateArray.length < 2) {
    dateArray.push(timeAgoS + (timeAgoS === 1 ? ' sec' : ' secs'));
  }

  return dateArray.join(' ') + ' ago';
};

export const TimeAgo = ({
  date,
  title,
}: {
  date: Date;
  title?: React.ReactElement;
}): JSX.Element => {
  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    setTimeAgo(updateTime(date));
    const timer = setInterval(() => {
      setTimeAgo(updateTime(date));
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [date]);

  if (!date) {
    return <></>;
  }

  return (
    <Box component={'span'} sx={{ whiteSpace: 'nowrap' }}>
      <Tooltip title={title || moment(date).format('YYYY-MM-DD HH:mm:ss.SSS')}>
        <div>{timeAgo}</div>
      </Tooltip>
    </Box>
  );
};
