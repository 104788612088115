import { Alert, Box, Snackbar } from '@mui/material';
import { useState } from 'react';
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';

export const CopyToClipboard = ({
  children,
  text,
}: {
  children: React.ReactNode;
  text: string;
}): JSX.Element => {
  const [open, setOpen] = useState(false);

  const onCopy = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Box
      component={'span'}
      sx={{
        cursor: 'pointer',
      }}>
      <ReactCopyToClipboard text={text} onCopy={onCopy}>
        {children}
      </ReactCopyToClipboard>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        message="Copied!"
        key={'copy-to-clipboard-message'}
        autoHideDuration={3000}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Copied!
        </Alert>
      </Snackbar>
    </Box>
  );
};
